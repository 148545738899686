import bg from '@/assets/img/bg.svg';
import loader from '@/assets/icon/loading.svg';
import loaderPrimary from '@/assets/icon/loading-primary.svg';

export const Loading1: React.FC= () => {
  return (
    <div className='relative h-screen overflow-hidden max-lg:min-h-[calc(100vh-env(safe-area-inset-bottom))]'>
      <main className='pb-[env(safe-area-inset-bottom)] overflow-y-auto h-full relative z-[1]'>
        <div className='w-full max-w-3xl lg:max-w-4xl mx-auto min-h-full flex items-center justify-center sm:p-8'>
          <img src={loader} alt='loading' width={32} height={32} className='animate-spin max-sm:hidden' />
          <img src={loaderPrimary} alt='loading' width={32} height={32} className='animate-spin sm:hidden' />
        </div>
      </main>

      <div className='absolute top-0 left-0 w-full h-full max-sm:hidden'>
        <img src={bg} alt='bg' width={1440} height={1024} className='w-full h-full object-cover' />
      </div>
    </div>
  );
};

export const Loading2: React.FC= () => {
  return (
    <div className='relative h-screen overflow-hidden max-lg:min-h-[calc(100vh-env(safe-area-inset-bottom))]'>
      <main className='pb-[env(safe-area-inset-bottom)] overflow-y-auto h-full relative z-[1]'>
        <div className='w-full max-w-3xl lg:max-w-4xl mx-auto min-h-full flex items-center justify-center sm:p-8'>
          <img src={loaderPrimary} alt='loading' width={32} height={32} className='animate-spin' />
        </div>
      </main>
    </div>
  );
};

export const Loading3: React.FC= () => {
  return (
    <div className='w-full h-[calc(100vh-250px)] flex items-center justify-center py-6'>
      <img src={loaderPrimary} alt='loading' width={32} height={32} className='animate-spin' />
    </div>
  );
};

export const Loading4: React.FC= () => {
  return (
    <img src={loaderPrimary} alt='loading' width={32} height={32} className='animate-spin' />
  );
};