import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type CbtType = {
  id: number;
  kementrian: string;
  nama_unit: string;
  satuan_kerja: string;
  kl: {
    name: string;
    sub: string;
  };
  kro: Event;
  ro: Event;
  tag_awal: Tag[];
  tag_rekomendasi: Tag[];
  tingkat_aksi: string;
  status: string;
  komponen: {
    data: Details[],
    pagination: Pagination
  };
}

export type CBTDetailComponentType = {
  id: number;
  provinsi: string;
  regency: string;
  pagu: number;
  alokasi: number;
  realisasi: number;
}

export type QueryParamsCBT = {
  page: number;
  limit: number;
  kementrian_ids: number[];
  ro_ids: number[];
  kro_ids: number[];
  search: string;
  year: string | null;
  tag_awal: number[];
  tingkat_aksi: string;
  status: string;
}

type Event = {
  id: string;
  kode: string;
  nama: string;
  satuan: string;
};

// type Modify = {
//   author: string;
//   date: string;
// };

type Tag = {
  status: string;
  title: string;
  detail?: string;
};

type Pagination = {
  total: number;
  per_page: number;
  current_page: number;
}

type Details = {
  id: number;
  komponen: {
    id: number;
    nama: string;
  },
  is_aksi_iklim: boolean;
  is_terkait_gender: boolean;
  jumlah_lokasi: number;
  anggaran: {
    pagu: number;
    alokasi: number;
    realisasi: number;
  }
}

type CbtTableState = {
  kl: boolean;
  kro: boolean;
  ro: boolean;
  tag_awal: boolean;
  tag_rekomendasi: boolean;
  tingkat_aksi: boolean;
  status: boolean;
  get_again: number;
  data: CbtType[];
  pagination: Pagination;
};

const initialState: CbtTableState = {
  kl: true,
  kro: true,
  ro: true,
  tag_awal: true,
  tag_rekomendasi: true,
  tingkat_aksi: true,
  status: true,
  get_again: 0,
  data: [],
  pagination: {
    total: 0,
    per_page: 10,
    current_page: 1,
  },
};

const cbtTableSlice = createSlice({
  name: 'cbt_table',
  initialState,
  reducers: {
    setCbtTable: (state, action: PayloadAction<Partial<CbtTableState>>) => {
      return {
        ...state,
        ...action.payload,
        pagination: {
          ...state.pagination,
          ...action.payload.pagination,
        },
      };
    },
    clearCbtTable: (state) => {
      return {
        ...initialState,
        pagination: {
          ...initialState.pagination,
        },
      };
    },
  },
});

export const { setCbtTable, clearCbtTable } = cbtTableSlice.actions;
export default cbtTableSlice.reducer;